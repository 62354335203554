<template>
  <div>
    <Header ref="header"/>

    <div class="container">
      <div class="w w1920">
        <div class="banner">
          <img :src="require('@/assets/images/meeting/main/banner.png')">
        </div>
        <div class="main">
          <div class="container">
            <div class="left">
              <!-- 左侧内容 -->
              <div class="rectangle">
                <!-- 生成 6 个 130x130 px 的正方形框 -->
                <div class="square" v-for="(item, index) in recommendList" v-bind:key="index">
                  <router-link :to="{path:'sup',query:{id:item.id}}" target="_blank">
                    <div v-if="item.logoImage">
                      <img :src="getRecommendCoverImage(item.logoImage)" style="width: 130px;height: 130px;"/>
                    </div>
                    <div v-else-if="item.logoText" class="text-content">{{item.logoText}}</div>
                    <div v-else class="text-content">{{item.supplierName}}</div>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="right">
              <!-- 右侧内容 -->
              <!-- 生成 4 行 5 列的 170x290 div 块 -->
              <!-- 20 -->
              <div class="grid-item" v-for="(good,index) in paging.list" v-bind:key="good.id">
                <div class="gl-i-wrap">
                  <div class="p-img">
                    <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
                      <img :src="getCoverImage(good.images)">
                    </router-link>
                  </div>
                  <div class="p-lie">
                    <div class="p-price">
                      <em>¥</em>{{good.price}}
                      <template v-if="good.subName">
                        <el-tooltip class="item" effect="dark" :content="good.subName" placement="bottom-end">
                          <el-tag size="mini" effect="dark">促</el-tag>
                        </el-tooltip>
                      </template>
                    </div>
                  </div>
                  <div class="p-name">
                    <router-link :to="{path:'goods',query:{id:good.id}}" target="_blank" tag="a">
                      {{good.name}}
                    </router-link>
                  </div>
                  <div class="sepc">
                    <p v-for="(para,index) in good.paras" v-bind:key="index">
                      <template v-if="para.name=='规格' || para.name=='有效期' || para.name=='大包装'">
                        {{para.name}}：{{para.value}}
                      </template>
                    </p>
                  </div>
                  <div class="p-operate">
                    <amount-box v-model="carts[index].addNum" :defaultVal="carts[index].addNum" :max="99999" :min="0"></amount-box>
                    <a href="javascript:void(0);" v-on:click="addCart(index,good)" class="addcart">
                      <i class="iconfont icon-carts"></i>加入购物车
                    </a>
                    <div style="clear: both"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pagination">
            <!-- 底部分页内容 -->
            <el-pagination
                background
                layout="prev, pager, next"
                :total="params.totalRecord" :current-page.sync="params.currentPage" :page-size="params.pageSize" @current-change="getRecommendList">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>
<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'

import {getInfo, getPage} from "@/api/mall/meeting/policy/supplier"
import {cartCreate, getGoodsSearch} from "@/api/good"
import AmountBox from "@/components/AmountBox/Index.vue";
import {getCartList} from "@/api/user";

export default {
  name: "marketingPromotion",
  data() {
    return {
      id: undefined,
      params: {
        currentPage: 1,
        pageSize: 20,
        totalRecord: 0
      },

      supplier: {},
      list: [],
      cartList:[],
      paging : {},
      recommendList: [],


      priceOrder:'price asc',
      priceIcon: 'icon-arrow-up',
      listType: false,
      sidx: 'Id',
      sord: 'desc',

      SearchLoading:false,

      extensions: [],      //推广

      carts : []
    }
  },
  components: {
    AmountBox,
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  created() {
    this.id = this.$route.query.id

    this.getInfo()
    this.getRecommendList()
  },
  methods:{
    getInfo(){
      getInfo(this.id).then(res => {
        this.supplier = res.data
        this.params.nos = this.supplier.goodsIds;

        if(this.params.nos != null) {
          this.getGoods()
        }
      });
    },
    getRecommendList(){
      getPage({currentPage: 1, pageSize: 6}).then(res => {
        this.recommendList = res.data.list
      });
    },
    getCoverImage(images){
      let json = JSON.parse(images)
      return json[0].attachmentId == '' ? this.staticUrl + '/app/no-pic.png' : this.staticUrl + '/' + json[0].imageUrl
    },

    getGoods(){
      this.carts = []
      this.cartList = []
      this.SearchLoading = true
      getGoodsSearch(this.params).then(res => {
        this.paging = res.data
        this.totalCount = res.data.pagination.totalRecord
        this.totalPage = res.data.pagination.totalPage

        const goodsIds = res.data.list.map((item) => {
          return item.id;
        });

        // this.totalCount = res.data.totalCount
        // this.totalPage = res.data.totalPages
        //
        // var productIds = res.data.list.map((item)=>{
        //   return item.productId;
        // })
        //获取当页购物车信息，如果获取全部代价有点大（比如有些客户购物出列表300+）
        getCartList({goodsIds:goodsIds.join(',')}).then(res=>{
          this.cartList = res.data.normal
          this.paging.list.forEach((i)=>{
            // if(i.image.indexOf("image.yabyy.com") == -1){
            //   i.image = settings.AdminUrl + i.image
            // }

            if(i.paramsData != null){
              i.paras = JSON.parse(i.paramsData.replace(/[\r|\n|\t]/g,''));
            }else{
              i.paras = []
            }

            let has = this.cartList.filter(item=>item.goodsId == i.goodsId)
            if(has.length>0){
              i.num = has[0].num
              i.addNum = 0
              this.carts.push({num: has[0].num,addNum:0});
            }else{
              i.num = 0
              i.addNum = 0
              this.carts.push({num: 0,addNum:0});
            }
          })
        })
      }).finally(()=>{
        this.SearchLoading = false
      })
    },

    getRecommendCoverImage(url){
      // let json = JSON.parse(images)
      // return json[0].attachmentId == '' ? this.staticUrl + '/app/no-pic.png' : this.staticUrl + '/' + json[0].imageUrl
      return this.staticUrl + '/' + url
    },

    addCart(index,goods){
      if(this.carts[index].addNum <= 0){
        this.$message({
          message: '购物车数量必须大于0',
          type: 'warning'
        })
      }else{
        let _this = this

        cartCreate({
          goodsId: goods.id,
          goodsNo: goods.no,
          qty: this.carts[index].addNum,
          price: goods.price,
          signId: 2,
          //skuId: this.showSelect.Id,
          specInfo : goods.specs
        }).then((res) => {
          // if (res.code == 400){
          // 	_this.$h.toast(res.msg);
          // }else{
          //_this.$store.commit('cart/setCartAdd', true);
          //_this.$store.commit('cart/cartNum', true);
          //_this.$h.toast('加入购物车成功');

          if(res!=undefined){
            _this.$message({
              message: '加入购物车成功',
              type: 'success'
            });

            _this.carts[index].num = this.carts[index].num + this.carts[index].addNum;
            _this.carts[index].addNum = 0;

            _this.$store.dispatch('GetCartSum');
          }
        }).catch(err => {
          console.log(err);
          // _this.$h.toast(err);
          // _this.$toError(err);
        });
      }
    },

  }
}
</script>
<style>


.main {
  /* 设置背景图片 */
  background-image: url('../../assets/images/meeting/main/sup_bg.png');
  background-size: 1920px 2036px;
  background-position: center;
  background-repeat: no-repeat;

  /* 设置背景颜色为红色 */
  background-color: #c02213;

  /* 设置容器的宽度和高度 */
  width: 100%;
  min-height: 2036px; /* 确保背景图片的最小高度 */

  /* 使用 Flexbox 布局 */
  display: flex;
  flex-direction: column; /* 垂直排列子元素 */
  align-items: center;
  justify-content: flex-start; /* 让内容靠上对齐 */

  /* 允许内容超出部分滚动 */
  overflow: auto;


  .container {
    display: flex;
    width: 1190px; /* 固定宽度为 1190px */
    height: 1360px; /* 左右区域的高度 */
    margin-top: 20px; /* 添加一些顶部间距 */
    margin-bottom: 20px; /* 添加一些底部间距 */
    margin-bottom: 10px; /* 下方间距10px */
  }

  .left {
    width: 200px;
    height: 100%;
    //background-color: #f0f0f0; /* 任意背景颜色 */
    .rectangle {
      width: 190px; /* 矩形框的宽度 */
      height: auto; /* 矩形框的高度，确保可以容纳6个130px的正方形和间距 */
      //background-color: #ffffff; /* 任意背景颜色 */
      display: flex;
      flex-direction: column;
      justify-content: flex-start; /* 从上到下排列 */
      align-items: center;
      border: 1px solid #ccc; /* 可根据需要调整边框 */
      padding-top: 10px; /* 添加顶部内边距 */
      padding-bottom: 10px; /* 添加底部内边距 */


      border: 1px solid #ccc; /* 可根据需要调整边框 */
      .square {
        width: 170px;
        height: 170px;
        background-color: white; /* 可根据需要调整颜色 */
        border: 1px solid #ccc; /* 可根据需要调整边框 */
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 10px;


        &:last-child {
          margin-bottom: 0; /* 最后一个元素不需要下方间距 */
        }
        .text-content {
          font-size: 30px; /* 设置字体大小 */
          text-align: center; /* 居中文本 */
        }
      }
    }
  }

  .right {
    flex-grow: 1;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, 170px); /* 5列，每列宽170px */
    grid-template-rows: repeat(4, 320px); /* 4行，每行高290px */
    gap: 20px; /* 网格项之间的间距，可根据需要调整 */
    //background-color: #ffffff; /* 任意背景颜色 */
    .grid-item {
      width: 175px;
      height: 320px;
      background-color: white; /* 可根据需要调整颜色 */
      border: 1px solid #ccc; /* 可根据需要调整边框 */
      box-sizing: border-box;
    }
  }


  .pagination {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    width: 100%;
    margin: 30px 0 20px 0; /* 添加一些上下间距 */

  }

  .active{
    background-color: red !important;
  }
}

.gl-i-wrap {padding: 0}
.gl-i-wrap .p-lie{line-height: 20px}
.gl-i-wrap .p-img{width: 173px; height: 173px;}
.gl-i-wrap .p-name a {height:18px;}

</style>