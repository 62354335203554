import { render, staticRenderFns } from "./sup.vue?vue&type=template&id=21f8f274&"
import script from "./sup.vue?vue&type=script&lang=js&"
export * from "./sup.vue?vue&type=script&lang=js&"
import style0 from "./sup.vue?vue&type=style&index=0&id=21f8f274&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports